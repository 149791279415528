<template>
  <div>
    <div v-if="isLoged">
      <b-container>
        <div v-if="user && user.has_changed_password == false">
          <ResetPassword></ResetPassword>
        </div>
        <div v-else>
          <template v-if="$maintenance_view">
            <MaintenanceView></MaintenanceView>
          </template>
        </div>
      </b-container>
      <template
        v-if="user && user.has_changed_password == true && !$maintenance_view"
      >
        <Dashboard class="dashboard-container"></Dashboard>
      </template>
    </div>
    <section v-if="app_name == 'KEY LEARNING' && !isLoged">
      <!-- <b-container>
        <section class="page-section mb-1 mt-1">
          <b-img v-bind:src="firstWelcomeImage" class="card-img"></b-img>
          <div
            class="card-img-overlay overlay-grey text-white d-flex justify-content-center align-items-center"
            style="z-index: -1"
          >
          </div>
        </section>
        <section class="page-section mb-1 mt-1">
          <b-container></b-container>
        </section>
        <section class="page-section">
          <b-container>
            <b-img
              v-bind:src="secondWelcomeImage"
              class="card-img"
              id="kl-welcome"
            ></b-img>
          </b-container>
        </section>
        <div class="main-info">
          <div class="main-info-content background-content-1">
            <h3 class="info-title">Características</h3>
            <ul>
              <li>
                Es una Plataforma para
                <strong>gestionar totalmente el proceso formativo,</strong>
                desde la construcción y/o
                <strong>modificación de los currículos,</strong> de manera
                integrada y automatizada, hasta el proceso
                <strong>Assessment</strong>
                de
                <strong
                  >cuantificación y cualificación de los logros de resultados de
                  aprendizajes.</strong
                >
              </li>
              <li>
                Se constituye en un
                <strong
                  >Sistema de Seguimiento de Resultados de Aprendizajes.</strong
                >
              </li>
              <li>
                Es una solución
                <strong>SaaS (Software as a Service) y Cloud,</strong> que se
                ofrece como un servicio seguro en la
                <strong>nube de AWS (Amazon Web Services),</strong> lo que
                incluye
                <strong
                  >mantención y soporte permanente a cada Institución</strong
                >
                que usa la plataforma. Con ello, la institución no requiere
                destinar recursos a hardware o software que deba soportar y
                mantener.
              </li>
              <li>
                En la
                <strong>plataforma Nativa KL es posible Customizar</strong> a
                las necesidades de la IES.
              </li>
            </ul>
          </div>
          <div class="main-info-picture ml-5 mt-4">
            <img class="mt-1" src="../../assets/servicio-cloud.png" alt="" />
          </div>
        </div>
        <div class="main-info">
          <div class="main-info-picture mr-4 mt-5">
            <img
              class="center-image"
              src="../../assets/analitica2.png"
              alt=""
            />
          </div>

          <div class="main-info-content background-content-2">
            <h3 class="info-title">Beneficios</h3>
            <ul>
              <li>
                Facilita la
                <strong
                  >definición y desarrollo de los Perfiles de Egreso</strong
                >
                en Competencias o Resultados de Aprendizajes.
              </li>
              <li>
                Simplifica y facilita la
                <strong
                  >construcción del currículo, a partir del Perfil de Egreso,
                  integrando todos los productos macro-curriculares </strong
                >(perfil, matrices de tributación, plan de estudio, malla
                curricular).
              </li>
              <li>
                Facilita y sistematiza la
                <strong
                  >integración macro y micro-curricular, conectando en línea con
                  los Programas de Asignaturas, secciones y generando la
                  Planificación, Ruta de Aprendiza o Syllabus</strong
                >
                del período semestral o anual.
              </li>
              <li>
                Agiliza y potencia el proceso de
                <strong
                  >Assessment efectivo de logros y seguimiento de resultados de
                  aprendizaje</strong
                >
                a nivel individual, por asignaturas, sección, carrera y global.
              </li>
              <li>
                El proceso de Assessment permite articular las
                <strong
                  >competencias, resultados de aprendizajes, indicadores de
                  logros</strong
                >
                en base al perfil de egreso, en base a notas y porcentaje de
                logros de los respectivos indicadores.
              </li>
              <li>
                Facilita a los profesores
                <strong
                  >elaborar su planificación y evaluaciones en línea,</strong
                >
                obteniendo los resultados automatizados.
              </li>
              <li>
                Permite a los estudiantes
                <strong>conocer sus programas y planificación en línea</strong>
                y obtienen
                <strong
                  >retroalimentación automática de sus evaluaciones.</strong
                >
              </li>
              <li>
                Evidencia la calidad de gestionar sistemáticamente los
                <strong
                  >procesos docentes para los sistemas de Acreditación.</strong
                >
              </li>
              <li>
                Genera
                <strong
                  >reportes automatizados, incluyendo seguimiento de la
                  progresión</strong
                >
                académica de los estudiantes.
              </li>
            </ul>
          </div>
        </div>
        <div class="main-info mb-5">
          <div class="main-info-content background-content-3">
            <h3 class="info-title">
              Funcionalidades (integradas y automatizadas)
            </h3>
            <ul>
              <li>
                Construye los Perfiles de Egreso en base a Competencias o RA.
              </li>
              <li>
                Genera matrices para desagregar las Competencias en resultados
                de aprendizajes por Ciclos Formativos y de creación de
                asignaturas.
              </li>
              <li>
                Diseña y crea en línea el Plan de Estudio, la Malla Curricular,
                los Programas de Asignatura y su Ruta de Aprendizaje o
                Planificación (syllabus) en forma integrada, articulada y
                automatizada.
              </li>
              <li>Diseña y crea en línea los instrumentos de evaluación.</li>
              <li>
                El Assessmet genera y aplica las evaluaciones concatenadas con
                los indicadores de logro de cada RA.
              </li>
              <li>
                Genera Actas digitales en línea para los comités de carrera y de
                currículo u otros.
              </li>
              <li>
                Facilita el proceso de caracterización a los nuevos estudiantes,
                a través de instrumentos propuestos o a requerimiento de la IES.
              </li>
              <li>
                Genera reportes nativos de gestión curricular y de assessment,
                además de otros que defina la IES.
              </li>
            </ul>
            <div id="contact"></div>
          </div>
          <div class="main-info-picture ml-5 mt-4">
            <img class="mt-4" src="@/assets/malla-curricular.png" alt="" />
          </div>
        </div>
      </b-container> -->
      <Home> </Home>
    </section>
    <!-- <section>
      <div class="contact">
        <div class="contact-box">
          <div class="contact-card">
            <h2 class="contact-label">Contáctanos</h2>
            <h4><strong>KEY LEARNING SPA</strong></h4>
            <div class="contact-items">
              <i class="bi bi-telephone-fill mr-3"></i>
              <a href="tel:+56223213350">+56(2) 2321 3350</a>
            </div>
            <div class="contact-items">
              <i class="bi bi-phone-fill mr-3"></i>
              <a href="tel:+56977081206">+56(9) 7708 1206</a>
            </div>
            <div class="contact-items">
              <i class="bi bi-geo-alt-fill mr-3"></i>
              <p class="w-50">
                Av. Américo Vespucio Sur #107, of. 207, Las Condes, Santiago.
              </p>
            </div>
            <div class="contact-items">
              <i class="bi bi-envelope-fill mr-3"></i>
              <p>keylearningspa@gmail.com</p>
            </div>
          </div>
          <div class="contact-form">
            <div class="contact-form-header">
              <b-input
                class="ml-0"
                type="text"
                id="subject"
                placeholder="Asunto"
              /><br />
            </div>
            <b-input
              class="ml-0"
              type="text"
              id="body"
              placeholder="Mensaje"
            /><br />
            <button type="button" id="generate">Enviar</button><br />
          </div>
        </div>
      </div>
    </section> -->
  </div>
  <!-- </b-container> -->
</template>

<script>
import { APP_NAME } from "@/utils/globals";
import { mapGetters } from "vuex";

export default {
  name: "App",
  components: {
    Dashboard: () => import("@/components/users/Dashboard"),
    ResetPassword: () => import("@/components/users/ResetPassword"),
    MaintenanceView: () => import("@/components/public/MaintenanceView"),
    Home: () => import("@/components/new-page/Home"),
  },
  data: () => ({
    gera_welcome1: require("@/assets/welcome1.png"),
    gera_welcome2: require("@/assets/welcome2.jpg"),

    klearning_welcome1: require("@/assets/KEYlearning-Welcome1.png"),
    klearning_welcome2: require("@/assets/KEYlearning-Welcome2.jpg"),
    app_name: APP_NAME,
  }),
  filters: {
    pretty: function (value) {
      try {
        return JSON.stringify(JSON.parse(value), null, 2);
      } catch (error) {
        return "";
      }
    },
  },
  computed: {
    ...mapGetters({
      isLoged: "isLoged",
      user: "getUser",
      institution: "getInstitution",
    }),
    firstWelcomeImage() {
      if (this.app_name == "GERA") return this.gera_welcome1;
      return this.klearning_welcome1;
    },
    secondWelcomeImage() {
      if (this.app_name == "GERA") return this.gera_welcome2;
      return this.klearning_welcome2;
    },
  },
};
</script>

<style>
.dashboard-container{
  width: 100% !important;
}
html {
  scroll-behavior: smooth;
}
.main-info {
  display: flex;
  padding: 3% 10%;
  width: 100%;
}
.info-title {
  padding-left: 4%;
  color: #006e9a !important;
}
.main-info-content {
  width: 60%;
  font-size: 17px;
  line-height: 26px;
  text-align: justify;
  color: #666;
}
.center-image {
  width: 70% !important;
  margin-top: 20%;
}

.contact {
  width: 100%;
  padding: 10% 10%;
  background-image: url("../../assets/cyber-security-17-1-1024x630.png"),
    radial-gradient(circle at top right, #14aab6 0%, #006e9a 100%) !important;
}

.contact-box {
  display: flex;
  height: 100%;
  box-shadow: 0px 40px 60px -8px rgb(0 0 0 / 30%);
  margin-top: -18%;
  border-radius: 10px;
}

.contact-card {
  width: 40%;
  background: transparent;
  padding-top: 4%;
  padding-bottom: 7% !important;
  padding-left: 8%;
  background: #fff;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
}

.contact-items {
  display: flex;
  font-size: 19px;
}

.contact-items i {
  color: #006e9a !important;
}

.contact-label {
  font-weight: 700;
  font-size: 44px;
  color: #006e9a !important;
}

.contact-form {
  background-size: contain;
  background-position: bottom left;
  background-image: url(../../assets/cyber-security-010-1.png);
  background-color: #14aab6;
  background-repeat: no-repeat;
  width: 60%;
  padding-top: 70px;
  padding-right: 40px;
  padding-bottom: 60px;
  padding-left: 140px;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
}

.contact-form-header {
  display: flex;
  padding-right: 1%;
}

.contact-form-header input {
  width: 100%;
}

input[type="text"] {
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

#generate {
  color: #ffffff !important;
  border-width: 0px !important;
  border-radius: 25px;
  background-color: #006e9a;
  font-size: 20px;
  padding: 6px 16px;
  margin-bottom: 1%;
  margin-right: 1%;
  float: right;
}

#generate:hover {
  background-color: #037faf;
  transition: 0.2s;
}

@media (max-width: 1025px) {
  .background-content-1 {
    background: linear-gradient(
        rgba(255, 255, 255, 0.78),
        rgba(255, 255, 255, 0.78)
      ),
      url("../../assets/servicio-cloud.png") no-repeat;
    background-position: center;
  }
  .background-content-2 {
    background: linear-gradient(
        rgba(255, 255, 255, 0.78),
        rgba(255, 255, 255, 0.78)
      ),
      url("../../assets/analitica2.png") no-repeat;
    background-position: center;
  }
  .background-content-3 {
    background: linear-gradient(
        rgba(255, 255, 255, 0.78),
        rgba(255, 255, 255, 0.78)
      ),
      url("../../assets/malla-curricular.png") no-repeat;
    background-position: center;
  }
  .main-info {
    display: block;
  }
  .main-info-picture {
    display: none;
  }
  .main-info-content {
    width: 100% !important;
  }
}
</style>
